// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Autocomplete copied from https://www.w3schools.com/howto/howto_js_autocomplete.asp
window.autocomplete = function (searchInput, tags, selectTag) {
  /*the autocomplete function takes two arguments,
  the text field element and an array of possible autocompleted values:*/
  var currentFocus;
  /*execute a function when someone writes in the text field:*/
  searchInput.addEventListener("input", function(e) {
      var a, b, i, search_value = this.value;
      /*close any already open lists of autocompleted values*/
      closeAllLists();
      if (!search_value) { return false;}
      currentFocus = -1;
      /*create a DIV element that will contain the items (values):*/
      a = document.createElement("DIV");
      a.setAttribute("id", this.id + "autocomplete-list");
      a.setAttribute("class", "autocomplete-items");
      /*append the DIV element as a child of the autocomplete container:*/
      this.parentNode.appendChild(a);
      /*for each item in the array...*/
      for (i = 0; i < tags.length; i++) {
        var tag_id = tags[i][0];
        var tag_name = tags[i][1];
        var search_match_index = removeDiacriticsAndCapitalize(tag_name).indexOf(removeDiacriticsAndCapitalize(search_value));
        if (search_match_index > -1) {
          /*create a DIV element for each matching element:*/
          b = document.createElement("DIV");
          b.id = tag_id;
          /*make the matching letters bold:*/
          b.innerHTML = tag_name.substr(0, search_match_index);
          b.innerHTML += "<strong>" + tag_name.substr(search_match_index, search_value.length) + "</strong>";
          b.innerHTML += tag_name.substr(search_match_index + search_value.length);
          /*insert a input field that will hold the current array item's value:*/
          b.innerHTML += "<input type='hidden' id='" + tag_id + "' value='" + tag_name + "'>";
          /*execute a function when someone clicks on the item value (DIV element):*/
          b.addEventListener("click", function(e) {
              var selected_item = this.getElementsByTagName("input")[0];
              /*insert the value for the autocomplete text field:*/
              searchInput.value = selected_item.value;
              /*close the list of autocompleted values,
              (or any other open lists of autocompleted values:*/
              selectTag(selected_item.id);
              closeAllLists();
          });
          a.appendChild(b);
        }
      }
  });
  /*execute a function presses a key on the keyboard:*/
  searchInput.addEventListener("keydown", function(e) {
      var autocompleteResults = document.getElementById(this.id + "autocomplete-list");
      if (autocompleteResults) autocompleteResults = autocompleteResults.getElementsByTagName("div");
      if (e.keyCode == 40) {
        /*If the arrow DOWN key is pressed,
        increase the currentFocus variable:*/
        currentFocus++;
        /*and and make the current item more visible:*/
        addActive(autocompleteResults);
      } else if (e.keyCode == 38) { //up
        /*If the arrow UP key is pressed,
        decrease the currentFocus variable:*/
        currentFocus--;
        /*and and make the current item more visible:*/
        addActive(autocompleteResults);
      } else if (e.keyCode == 13) {
        /*If the ENTER key is pressed, prevent the form from being submitted,*/
        e.preventDefault();
        var selectedIndex = undefined;
        if (autocompleteResults && currentFocus > -1) {
          selectedIndex = currentFocus;
        } else if (autocompleteResults && autocompleteResults.length == 1) {
          /*or if only one search result is present select that:*/
          selectedIndex = 0;
        }
        if (selectedIndex !== undefined) {
          autocompleteResults[selectedIndex].click();
        }
      }
  });
  function addActive(x) {
    /*a function to classify an item as "active":*/
    if (!x) return false;
    /*start by removing the "active" class on all items:*/
    removeActive(x);
    if (currentFocus >= x.length) currentFocus = 0;
    if (currentFocus < 0) currentFocus = (x.length - 1);
    /*add class "autocomplete-active":*/
    x[currentFocus].classList.add("autocomplete-active");
  }
  function removeActive(x) {
    /*a function to remove the "active" class from all autocomplete items:*/
    for (var i = 0; i < x.length; i++) {
      x[i].classList.remove("autocomplete-active");
    }
  }
  function closeAllLists(elmnt) {
    /*close all autocomplete lists in the document,
    except the one passed as an argument:*/
    var x = document.getElementsByClassName("autocomplete-items");
    for (var i = 0; i < x.length; i++) {
      if (elmnt != x[i] && elmnt != searchInput) {
      x[i].parentNode.removeChild(x[i]);
    }
  }
}
  /*execute a function when someone clicks in the document:*/
  document.addEventListener("click", function (e) {
      closeAllLists(e.target);
  });

  function removeDiacriticsAndCapitalize(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
  }
}

function setCanary() {
  let canary = document.getElementById('canary');
  if (canary !== null) {
    canary.parentElement.addEventListener("submit", function() {
      canary.value = 'coalmine';
    });
  }
}

function notTakenNewsletterAction() {
  return localStorage.getItem("subscribed-to-newsletter") !== "true";
}

function newsletterActionTaken() {
  localStorage.setItem("subscribed-to-newsletter", "true");
}

function setNewsletterOnsubmitAction(onSuccessCallback) {
  $("#newsletter-signup-form").on('submit', function(e){
    $.ajax({
      url: '/subscriptions',
      type: 'post',
      dataType: 'json',
      data: $('#newsletter-signup-form').serialize(),
      success: function(data) {
        $("#newsletter-signup-form").html("Prihlásenie prebehlo úspešne. Ďakujeme!");
        newsletterActionTaken();
        onSuccessCallback();
      }
    });
    return false;
  });
}

function initFixedNewsletterForm() {
  setNewsletterOnsubmitAction(() => {
    window.setTimeout(function() {
      $("#fixed-newsletter-footer").hide(100);
    }, 3000);
  });
  document.addEventListener("scroll", (event) => {
    if (notTakenNewsletterAction()) {
      $("#fixed-newsletter-footer").show(400);
    }
  });

  document.getElementById('newsletter-sub-close').addEventListener("click", () => {
    $("#fixed-newsletter-footer").hide(100);
    newsletterActionTaken();
  });
}

function initNewsletterForm() {
  if (notTakenNewsletterAction()) {
    $("#newsletter-section").show();
  }
  setNewsletterOnsubmitAction(() => {
    window.setTimeout(function() {
      $("#newsletter-section").hide();
    }, 3000);
  });
}

function initBurgerMenu() {
  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {
    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {
        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });
  }
}

document.addEventListener("turbolinks:load", ()=> {
  setCanary();
  initBurgerMenu();
  // initNewsletterForm();
  initFixedNewsletterForm();
});
